<template>
    <div class="godownEntry_mian">

        <div class="mian_top" >
            <el-form :model="form" :inline="true" style="height:40px;">

                <el-form-item label="门店" class="queryCondition">
                    <el-select  v-model="form.hierarchy_id" clearable placeholder="请选择门店">
                        <el-option
                            v-for="item in shopList"
                            :key="item.hierarchy_id"
                            :label="item.hierarchy_name"
                            :value="item.hierarchy_id">
                        </el-option>
                    </el-select>
                </el-form-item>
              
                <el-form-item label="应用分类" class="queryCondition" >
                    <el-select  v-model="form.attr_id" clearable placeholder="请选择应用分类">
                        <el-option
                            v-for="item in typeList"
                            :key="item.goods_type_id"
                            :label="item.title"
                            :value="item.goods_type_id">
                        </el-option>
                    </el-select>
                </el-form-item>

                  <el-form-item label="状态" class="queryCondition" >
                     <el-select  v-model="form.status" clearable placeholder="请选择状态">
                        <el-option
                        v-for="item in stateList"
                        :key="item.state"
                        :label="item.label"
                        :value="item.type">
                        </el-option>
                    </el-select>
                  </el-form-item>

                <div class="mian_top_right">
                    <el-button type="primary" class="senior_screening_query" @click="getList">查询</el-button>
                    <el-button class="senior_screening_reset" @click="resetForm()">重置</el-button>
                </div>

            </el-form>
        </div>

        <div class="mian_tab">
            <div class="headerBox">
                <div class="headerBox_title">
                    <span>电子证书列表</span>
                </div>

                <div class="operate">
                    <el-button type="primary" icon="el-icon-plus" size="mini" @click="toAddElect" >新增</el-button>
                    <!-- <el-button type="danger" icon="el-icon-delete" size="mini"  @click="deleteList"  >删除</el-button> -->
                    <!-- <el-button icon="el-icon-copy-document" size="mini"  @click="parentMethod(5)">复制模板</el-button> -->
                    <el-button icon="el-icon-refresh" size="mini"  @click="flushTable">刷新</el-button>
                </div>
            </div>

            <el-table @row-dblclick="handleDalclick"  width="100%" :data='tableData' stripe v-loading="loading" height="650" >
                
                <el-table-column
                    type="index"
                    width="50"
                    align="center"
                    label="序号">
                </el-table-column>

                <el-table-column
                    prop="title"
                    label="证书模板"
                    align="center">
                </el-table-column>

                <el-table-column
                    prop="hierarchy_names"
                    label="应用门店"
                    align="center">
                </el-table-column>

                <el-table-column
                    prop="attr_value_names"
                    label="使用品类"
                    align="center"
                   >
                </el-table-column>

                 <el-table-column
                    prop="brand_names"
                    label="使用品牌"
                    align="center"
                   >
                </el-table-column>

                 <el-table-column
                    prop="status"
                    label="状态"
                    align="center"
                   >
                   <template slot-scope="scope">
                          <el-switch
                            @change="changeSwitch(scope.row.status,scope.row.certificate_set_id)"
                            v-model="scope.row.status"
                            active-color="#409eff"
                            :active-value="10"
                            :inactive-value="20">
                        </el-switch>
                   </template>
                </el-table-column>

                <el-table-column
                    prop="create_store_user_name"
                    label="添加人"
                    align="center"
                   >
                </el-table-column>

                 <el-table-column
                    prop="create_time"
                    label="创建时间"
                    align="center"
                   >
                </el-table-column>

                <el-table-column
                    prop="update_store_user_name"
                    label="修改人"
                    align="center"
                   >
                </el-table-column>

                <el-table-column
                    prop="update_time"
                    label="修改时间"
                    align="center">
                </el-table-column>

                <el-table-column align='center' fixed='right'  field_alias='operation' label='操作'>
                    <template slot-scope='scope'>
                        <el-button type='text' @click="deleteList(scope.row.certificate_set_id,scope.$index)" style="color: #F56C6C;">删除</el-button>
                        <el-button type='text' @click="toCopy(scope.row.certificate_set_id)">复制模板</el-button>
                    </template>
                </el-table-column>


            </el-table>

            <div class="pagina">
                <el-pagination
                    :current-page="form.page"
                    background
                    @size-change="handleSizeChange"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="total"
                    :page-size="$store.state.pageSizes[0]"
                    :page-sizes="$store.state.pageSizes"
                    @current-change="handleCurrentChange"
                ></el-pagination>
            </div> 

        </div>
    
    </div>
</template>

<script>
import { getList,deleteList,updateStatus  } from '@/api/system/electronic';
import {getMerchantList,getTypeList } from '@/api/order/offlineOrder/commission'
export default{
    data(){
        return{
            loading:false,
            form:{
                hierarchy_id:'',
                status:'',
                attr_id:'',
                page:1,
                limit:15,
            },
            stateList:[
                {label:'开启',type:10},
                {label:'关闭',type:20},
            ],
            shopList:[],
            typeList:[],
            total:0,
          
            tableData: [],
        }
    },
    created(){
        this.getList()
        this.getMerchantList()
        this.getTypeList()
    },
    mounted(){
        var self=this;
        this.$bus.$on('electronicUpdateList',function(value){
            self.getList()
        })
    },
    methods:{

        flushTable(){
            this.loading=true
            this.getList()
            setTimeout(()=>{
                this.loading=false
            },1000)
        },
        
        //复制模板
        toCopy(id){
            this.$router.push({
              path:'/system/electronic/addElectronic/'+id,
              query: {
                  metaTitle:'复制模板',
                  istype:'copy',
                  t:Date.now(),
                }
            });
        },

        // 更新状态
        changeSwitch(val,id){
            updateStatus({
                certificate_set_id:id,
                status:val
            }).then(res=>{
                if(res.code==1){
                    console.log(res)
                }
            })
        },

        // 删除
        deleteList(id,index){
              this.$confirm(`确定删除?`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                deleteList({
                        certificate_set_id:id
                }).then(res=>{
                    if(res.code==1){
                        this.$message({
                            type:'success',
                            message:'删除成功',
                        })
                        this.tableData.splice(index,1)
                    }
                })
            }).catch(() => {});
        },

         //获取品类列表
        getTypeList(){
            getTypeList().then(res=>{
                if(res.code==1){
                    this.typeList=res.data
                }
            })
        },      

         //获取门店列表
        getMerchantList(){
            getMerchantList().then(res=>{
                if(res.code==1){
                    let shopList= res.data
                    shopList.map(item=>{
                        item.hierarchy_id=Number.parseInt(item.hierarchy_id)
                    })
                    this.shopList=shopList
                }
            })
        },
        
        // 获取列表
        getList(){
            getList({
                ...this.form
            }).then(res=>{
                if(res.code==1){
                    this.tableData=res.data.list
                    this.total=res.data.total
                    
                }
            })
        },
        
        // 重置
        resetForm(){
            this.form={
                page:1,
                limit:15,
                hierarchy_id:'',
                attr_id:'',
                status:'',
            }
        },
        
        // 编辑
        handleDalclick(row){
            this.$router.push({
              path:'/system/electronic/addElectronic/'+row.certificate_set_id,
              query: {
                  metaTitle: '电子证书详情',
                  t:Date.now(),
                }
            });
        },

        // 新增页面
        toAddElect(){
             this.$router.push({
              path:'/system/electronic/addElectronic',
              query: {
                  metaTitle: '新增电子证书',
                  istype:'detail',
              
                  t:Date.now(),
                }
            });
        },

          // 改变分页条数
        handleSizeChange(size) {
            this.form.limit = size;
            this.getList();
        },

        handleCurrentChange(page) {
            this.form.page = page;
            this.getList();
        },

    }

}
</script>

<style lang='less' scoped>
 .godownEntry_mian{
    .el-input .el-input__inner {
      font-size: 14px;
      height: 32px;
      line-height: 32px;
    }
    .mian_footer{
        position: sticky;
        left: 0px;
        bottom: 0px;
        background: #fff;
        width: calc(100% );
        padding-bottom: 10px;
       .mian_page{
        padding: 15px 0;
        background: #fff;
        margin-right: 20px;
        display: flex;
        justify-content: flex-end;

        .el-pagination.is-background .el-pager li {
            border-radius: 2px;
            font-weight: normal;
            background-color:#FFFFFF;
            border: 1px solid #D9D9D9;
            width: 32px;
            height: 32px;
            line-height: 32px;
        }
        .el-pagination.is-background .el-pager li:not(.disabled).active {
            background-color: #409EFF;
            color: #FFF;
        }
        .el-pagination.is-background .btn-prev {
            border-radius: 2px;
            border: 1px solid #ddd;
            background: white;
            height: 32px;
            line-height: 32px;
        }
        .el-pagination.is-background .btn-next {
            border-radius: 2px;
            border: 1px solid #ddd;
            background: white;
            height: 32px;
            line-height: 32px;
        }
      }
    }
  }
   .mian_top{
    background: #fff;
    padding: 9px 20px;
    margin-bottom: 12px;
    .mian_top_right{
      float: right;
      display: flex;
      padding-top:3px;
      .senior_screening_btn{
        width: 80px;
        height: 32px;
        line-height: 32px;
        text-align: center;
        background: #FFFFFF;
        border-radius: 4px 4px 4px 4px;
        border: 1px solid #0075FF;
        font-size: 14px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #409EFF;
        cursor: pointer;
      }
      .senior_screening_query{
        width: 68px;
        height: 32px;
        line-height: 32px;
        background: #409EFF;
        text-align: center;
        color: #fff;
        border-radius: 4px 4px 4px 4px;
        margin-left: 20px;
        margin-right: 8px;
        cursor: pointer;
        padding: 0;
      }
      .senior_screening_reset{
        width: 68px;
        height: 32px;
        line-height: 32px;
        background: rgba(255,255,255,0.1);
        border-radius: 4px 4px 4px 4px;
        opacity: 1;
        border: 1px solid #D8D8D8;
        font-size: 14px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #595959;
        text-align: center;
        cursor: pointer;
        padding: 0;
      }
    }
  }
   .mian_tab{
    height:calc(100% - 24px);
    background: #fff;
    padding: 12px 20px;
    min-height: 500px;
    .listTable{
      height:calc(100% - 1540px);
    }
    .headerBox_title{
      font-size: 16px;
      font-family: Microsoft YaHei-Bold, Microsoft YaHei;
      font-weight: bold;
      color: #595959;

    }
    .headerBox{
      display: flex;
      justify-content: space-between;
      margin-bottom: 12px;
      align-items: center;
    }
  }
</style>